import { useState, useEffect } from 'react'
import { Send, Phone, Check } from 'lucide-react'
import { useNavigate, useParams } from 'react-router-dom'
import axios, { AxiosResponse } from 'axios'


export default function ChatPage() {
  const params = useParams();
  const navigate = useNavigate();

  /**
   * UserSession
   */
  const [newMessage, setNewMessage] = useState('');
  const [session, setSession] = useState<{
    id: string,
    user_id: string,
    messages: {
      id: string,
      role: string,
      content: string,
      time: string,
      marks: any
    }[],
    timestamp: number,
    summary: string,
    stage: string,
  }>();
  const [canUpgrade, setCanUpgrade] = useState<boolean>(false);

  const accessToken = localStorage.getItem('tmp::access_token');

  /**
   * Auto-scroll chat
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [session]);

  /**
   * Load session history
   */
  useEffect(() => {
    axios.get(`${globalThis.dzenyUrl}/v1/user_session/${params.sessionId}`, {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
      }
    })
      .then(function(response) {
        setSession({
          ...response.data,
          messages: response.data.messages.map((msg: any) => {
            const date = new Date(msg.timestamp * 10);
            const formattedDate = new Intl.DateTimeFormat(
              'en-US',
              { hour: '2-digit', minute: '2-digit' }
            ).format(date);
            return { ...msg, time: formattedDate };
          })
        })
      })
      .catch(function(error) {
        console.log(error);
      })
  }, []);


  const handleSendMessage = (e: React.FormEvent) => {
    e.preventDefault();
    const msg = newMessage.trim();
    setNewMessage('');
    setCanUpgrade(false);
    if (msg) {
      axios
        .post(
          `${globalThis.dzenyUrl}/v1/chat/user_session/${params.sessionId}/text`,
          { text: msg },
          {
            headers: {
              'Accept': 'text/event-stream',
              'Authorization': `Bearer ${accessToken}`,
            },
            responseType: 'stream',
            adapter: 'fetch',
          }
        )
        .then(async (response) => { await processEventStream(response) })
        .catch(function(error) {
          console.log(error);
        })
    }
  }

  const handleUpgradeSession = () => {
    setCanUpgrade(false);
    if (session && session.stage === 'collecting') {
      axios
        .post(`${globalThis.dzenyUrl}/v1/user_session/${params.sessionId}/upgrade`, {},
          {
            headers: {
              "Authorization": `Bearer ${accessToken}`,
            }
          }
        )
        .then((response: AxiosResponse<any, any>) => {
          setSession(response.data);
        })
        .catch(function(error) {
          console.log(error);
        })
    }
  }

  const handleFinishSession = () => {
    axios
      .post(`${globalThis.dzenyUrl}/v1/user_session/${params.sessionId}/finish`, {},
        {
          headers: {
            "Authorization": `Bearer ${accessToken}`,
          }
        }
      )
      .then((response: AxiosResponse<any, any>) => {
        navigate("/profile")
      })
      .catch(function(error) {
        console.log(error);
      })
  }

  const processEventStream = async (response: AxiosResponse<any, any>) => {
    const stream = response.data;
    const reader = stream.pipeThrough(new TextDecoderStream()).getReader();
    let msgs = [];
    let assistantMsg = '';
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;
      const events = value.split('\n\n');
      for (let i = 0; i < events.length - 1; i++) {
        let event;
        try {
          event = JSON.parse(events[i]);
        } catch (e) {
          // FIXME: super ugly and buggy workaround
          console.log(e, events[i]);
          continue;
        }
        if (event.type === 'message.info' && session) {
          const date = new Date(event.timestamp * 10);
          const formattedDate = new Intl.DateTimeFormat(
            'en-US',
            { hour: '2-digit', minute: '2-digit' }
          ).format(date);
          msgs.push({
            id: event.id,
            role: event.role,
            content: event.content,
            time: formattedDate,
            marks: event.marks,
          })
          setSession({ ...session, messages: [...session.messages, ...msgs] });
        }
        else if (event.type === 'message.delta' && session) {
          assistantMsg += event.delta;
          setSession({
            ...session, messages: [...session.messages, ...msgs, {
              id: event.id,
              role: 'assistant',
              content: assistantMsg,
              time: '',
              marks: {},
            }]
          });
        }
        else if (event.type === 'session.upgrade_trigger' && session) {
          setCanUpgrade(true);
        }
      }
    }
    if (session) {
      setSession({ ...session, messages: [...session.messages, ...msgs] });
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-900 text-gray-100">
      <header className="bg-gray-800 p-4 flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <h1 className="text-xl font-semibold">Сессия</h1>
        </div>
        <div className="flex space-x-4">
          <button
            onClick={() => handleFinishSession()}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out flex"
            aria-label="Finish session"
          >
            Завершить
            <Check size={22} className="ml-1" />
          </button>
          <button
            onClick={() => navigate("/profile")}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            aria-label="Go to home page"
          >
            Назад
          </button>
        </div>
      </header>
      <main className="flex-1 overflow-y-auto p-4" data-conversation-content>
        <div className="space-y-4">
          {session && session.messages.map((message) => (
            <div
              key={message.id}
              className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
            >
              <div
                className={`max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl rounded-lg p-3 ${message.role === 'user' ? 'bg-blue-600' : 'bg-gray-700'}`}
              >
                <div className="flex justify-between items-baseline mb-1">
                  <span className="text-xs opacity-75">{message.time}</span>
                </div>
                <p>{message.content}</p>
              </div>
            </div>
          ))}
          {canUpgrade &&
            <div key='upgrade' className='flex flex-col items-center'>
              <div
                onClick={() => handleUpgradeSession()}
                className='max-w-xs md:max-w-md rounded-lg p-1 bg-green-700 hover:bg-green-900'
              >
                <p className="text-xs opacity-70">Перейти на следующий этап сессии</p>
              </div>
            </div>
          }
        </div>
      </main>
      <footer className="bg-gray-800 p-4">
        <form onSubmit={handleSendMessage} className="flex space-x-4">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type a message..."
            className="flex-1 bg-gray-700 text-white rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            type="button"
            onClick={() => navigate(`/voiceCall/${params.sessionId}`)}
            className="p-2 hover:bg-gray-700 rounded-full"
            aria-label="Voice call"
          >
            <Phone size={20} />
          </button>
          <button
            type="submit"
            className="bg-blue-600 hover:bg-blue-700 rounded-full p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            aria-label="Send message"
          >
            <Send size={20} />
          </button>
        </form>
      </footer>
    </div>
  )
}
